// extracted by mini-css-extract-plugin
export var body = "partnerships--founders-module--body--5a2d4";
export var bodyLarge = "partnerships--founders-module--bodyLarge--8adb2";
export var bodyLargeLight = "partnerships--founders-module--bodyLargeLight--f8ba8";
export var bodySmall = "partnerships--founders-module--bodySmall--a80c7";
export var bold = "partnerships--founders-module--bold--d5187";
export var disclaimerText = "partnerships--founders-module--disclaimerText--c1edd";
export var eyebrow = "partnerships--founders-module--eyebrow--93ecb";
export var filter = "partnerships--founders-module--filter--e81f2";
export var grid = "partnerships--founders-module--grid--e78e0";
export var homepageHeadline = "partnerships--founders-module--homepageHeadline--2f063";
export var internalHeadline = "partnerships--founders-module--internalHeadline--5882c";
export var italic = "partnerships--founders-module--italic--b177f";
export var link = "partnerships--founders-module--link--434af";
export var linkSmall = "partnerships--founders-module--linkSmall--43878";
export var quote = "partnerships--founders-module--quote--c6233";
export var subHeader = "partnerships--founders-module--subHeader--45c04";
export var subsubHeader = "partnerships--founders-module--subsubHeader--ccbc1";