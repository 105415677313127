import { hasNames } from "../utils";
import BaseModal from "./base-modal";
import * as React from "react";

import * as styles from "../styles/partnership-block.module.scss";

const PartnershipBlock = ({
	name,
	logo,
	colorLogo,
	investments = "",
	status = "",
	description = "",
	className = "",
	cornerClip = false,
	openFn = () => {},
	closeFn = () => {},
	nextFn = null,
	prevFn = null,
	isOpen = false,
}) => {
	return (
		<>
			<button
				className={hasNames(
					className,
					styles.partnershipBlock,
					cornerClip ? styles.cornerClipped : null
				)}
				onClick={openFn}>
				<div className={styles.grid}>
					<div className={styles.logo}>{logo}</div>
				</div>
			</button>
			<BaseModal
				className="test"
				onRequestClose={closeFn}
				isOpen={isOpen}
				modalClass={styles.modalClass}
				limitHeight={true}
				Content={
					<div className={styles.modal}>
						<div className={styles.headerBlock}>
							<div className={styles.logoWrap}>{colorLogo}</div>
							<div className={styles.titles}>
								<h2 className={styles.name}>{name}</h2>
								{ investments === '' ? '' : <div className={styles.detail}>
									<em>Investment:</em>
									{investments}
								</div>}
								<div className={styles.detail}>
									<em>Status:</em>
									{status}
								</div>
							</div>
						</div>
						<div className={styles.copy}>{description}</div>
					</div>
				}
				next={nextFn}
				previous={prevFn}
			/>
		</>
	);
};

export default PartnershipBlock;
