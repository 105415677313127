// extracted by mini-css-extract-plugin
export var angle = "partnership-block-module--angle--64a3e";
export var body = "partnership-block-module--body--150e4";
export var bodyLarge = "partnership-block-module--bodyLarge--ccf83";
export var bodyLargeLight = "partnership-block-module--bodyLargeLight--20b22";
export var bodySmall = "partnership-block-module--bodySmall--e5840";
export var bold = "partnership-block-module--bold--5ad1e";
export var copy = "partnership-block-module--copy--7eabf";
export var cornerClipped = "partnership-block-module--cornerClipped--23002";
export var detail = "partnership-block-module--detail--25d15";
export var details = "partnership-block-module--details--ff5b8";
export var detailsWrap = "partnership-block-module--detailsWrap--bb21a";
export var eyebrow = "partnership-block-module--eyebrow--96708";
export var filter = "partnership-block-module--filter--71d7a";
export var grid = "partnership-block-module--grid--74114";
export var headerBlock = "partnership-block-module--headerBlock--19620";
export var homepageHeadline = "partnership-block-module--homepageHeadline--20b81";
export var internalHeadline = "partnership-block-module--internalHeadline--63949";
export var italic = "partnership-block-module--italic--d5e31";
export var link = "partnership-block-module--link--e26a9";
export var linkSmall = "partnership-block-module--linkSmall--a7578";
export var logo = "partnership-block-module--logo--ffdbf";
export var logoWrap = "partnership-block-module--logoWrap--9091e";
export var modal = "partnership-block-module--modal--11ab7";
export var modalClass = "partnership-block-module--modalClass--fef4b";
export var name = "partnership-block-module--name--176db";
export var open = "partnership-block-module--open--36edd";
export var partnershipBlock = "partnership-block-module--partnershipBlock--f5411";
export var quote = "partnership-block-module--quote--adf1e";
export var titles = "partnership-block-module--titles--1cab5";