import { hasNames } from "../utils";
import Button from "./button";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";
import * as React from "react";
import { useState } from "react";



import ChevronLeft from "../images/chevron-left.icon.svg";
import ChevronRight from "../images/chevron-right.icon.svg";

import * as styles from "../styles/founder-stories.module.scss";
import * as grd from "../styles/grid.module.scss";
import * as typ from "../styles/typography.module.scss";

const FoundersStories = ({ useRef = null }) => {
	const dataRaw = useStaticQuery(graphql`
		query FounderStoriesDataQuery {
			founderStories: allMarkdownRemark(
				filter: { fileAbsolutePath: { regex: "/src/cms/data/stories/" } }
				sort: { fields: frontmatter___position }
			) {
				nodes {
					frontmatter {
						title
						image {
							id
							childImageSharp {
								gatsbyImageData(aspectRatio: 1, layout: CONSTRAINED, width: 528)
							}
						}
						position
					}
					html
				}
			}
		}
	`);
	const data = dataRaw?.founderStories?.nodes;

	const [currSlide, setCurrSlide] = useState(0);
	function prevQuote() {
		let n = currSlide - 1;
		if (n < 0) {
			n = data.length - 1;
		}
		setCurrSlide(n);
	}
	function nextQuote() {
		let n = currSlide + 1;
		if (n > data.length - 1) {
			n = 0;
		}
		setCurrSlide(n);
	}

	const [mobileOpen, setMobileOpen] = useState(false);
	function toggleMobileRead() {
		setMobileOpen(!mobileOpen);
	}

	return (
		<div className={hasNames(styles.carousel)} ref={useRef}>
			<div className={styles.childWrap}>
				{data.map((child, i) => {
					return (
						<div
							key={JSON.stringify(child)}
							className={hasNames(
								styles.child,
								currSlide === i ? "active" : null
							)}>
							<Story
								image={
									<GatsbyImage
										image={getImage(child.frontmatter.image)}
										alt={child.frontmatter.title}
									/>
								}
								title={child.frontmatter.title}
								story={child.html}
								isOpen={mobileOpen}
								toggleOpen={toggleMobileRead}
							/>
						</div>
					);
				})}
			</div>
			<div className={styles.moverShakerWrapper}>
				<div className={styles.label}>Other Founder Stories</div>
				<div className={styles.moverShaker}>
					<Button
						compact="true"
						onClick={prevQuote}
						type="btnAltSecondary"
						className={styles.arrows}>
						<ChevronLeft className={styles.chevron} />
					</Button>
					<div className={styles.dots}>
						{data.map((q, n) => {
							return (
								<button
									className={hasNames(
										styles.dot,
										n === currSlide ? "active" : ""
									)}
									onClick={() => {
										setCurrSlide(n);
									}}
									key={`founders-quote-carousel-dot-${n}`}>
									&bull;
								</button>
							);
						})}
					</div>
					<Button
						compact="true"
						onClick={nextQuote}
						type="btnAltSecondary"
						className={styles.arrows}>
						<ChevronRight className={styles.chevron} />
					</Button>
				</div>
			</div>
		</div>
	);
};

export default FoundersStories;

const Story = ({
	image = (
		<StaticImage
			src="../images/fallback-image.png"
			alt="Placeholder Image"
			aspectRatio={1 / 1}
			width={528}
		/>
	),
	title = "You should provide a title for this story.",
	story = "You didn't but it's not intended to be used that way so you're seeing this.",
	isOpen = false,
	toggleOpen = () => {},
}) => {
	return (
		<div className={grd.container}>
			<div className={styles.story}>
				<div className={styles.image}>{image}</div>
				<div>
					<div className={typ.eyebrow}>Founder Story</div>
					<h3 className={styles.title}>{title}</h3>
				</div>
				<div className={hasNames(styles.bodyWrap, isOpen ? "open" : null)}>
					<div
						className={styles.body}
						dangerouslySetInnerHTML={{ __html: story }}
					/>
					<div className={styles.readMore}>
						<Button type="tertiarySolid" onClick={toggleOpen}>
							{isOpen ? "Collapse Story" : "Read More"}
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};
