// extracted by mini-css-extract-plugin
export var arrows = "founder-stories-module--arrows--477e0";
export var body = "founder-stories-module--body--cb229";
export var bodyLarge = "founder-stories-module--bodyLarge--84a41";
export var bodyLargeLight = "founder-stories-module--bodyLargeLight--5c87c";
export var bodySmall = "founder-stories-module--bodySmall--90d8d";
export var bodyWrap = "founder-stories-module--bodyWrap--5f17c";
export var bold = "founder-stories-module--bold--14f21";
export var browedHeader = "founder-stories-module--browedHeader--048f8";
export var carousel = "founder-stories-module--carousel--58124";
export var centerlineButter = "founder-stories-module--centerlineButter--17acd";
export var centerlineHeader = "founder-stories-module--centerlineHeader--460f2";
export var chevron = "founder-stories-module--chevron--6d0ad";
export var child = "founder-stories-module--child--20e73";
export var childWrap = "founder-stories-module--childWrap--72598";
export var col0 = "founder-stories-module--col0--659ea";
export var col1 = "founder-stories-module--col1--2f0d2";
export var col10 = "founder-stories-module--col10--c0901";
export var col11 = "founder-stories-module--col11--2bc37";
export var col12 = "founder-stories-module--col12--75165";
export var col2 = "founder-stories-module--col2--f56b8";
export var col3 = "founder-stories-module--col3--b1022";
export var col4 = "founder-stories-module--col4--2da71";
export var col5 = "founder-stories-module--col5--32422";
export var col6 = "founder-stories-module--col6--94ff1";
export var col7 = "founder-stories-module--col7--b4bdb";
export var col8 = "founder-stories-module--col8--e3069";
export var col9 = "founder-stories-module--col9--9fcfe";
export var colLg0 = "founder-stories-module--colLg0--89a31";
export var colLg1 = "founder-stories-module--colLg1--62ac1";
export var colLg10 = "founder-stories-module--colLg10--39933";
export var colLg11 = "founder-stories-module--colLg11--41230";
export var colLg12 = "founder-stories-module--colLg12--0ef10";
export var colLg2 = "founder-stories-module--colLg2--1e489";
export var colLg3 = "founder-stories-module--colLg3--5518e";
export var colLg4 = "founder-stories-module--colLg4--52f96";
export var colLg5 = "founder-stories-module--colLg5--d5ab1";
export var colLg6 = "founder-stories-module--colLg6--06890";
export var colLg7 = "founder-stories-module--colLg7--d89a3";
export var colLg8 = "founder-stories-module--colLg8--2c9da";
export var colLg9 = "founder-stories-module--colLg9--d3b38";
export var colMd0 = "founder-stories-module--colMd0--365ad";
export var colMd1 = "founder-stories-module--colMd1--049be";
export var colMd10 = "founder-stories-module--colMd10--3f8ef";
export var colMd11 = "founder-stories-module--colMd11--17b35";
export var colMd12 = "founder-stories-module--colMd12--bc28a";
export var colMd2 = "founder-stories-module--colMd2--0992f";
export var colMd3 = "founder-stories-module--colMd3--9b123";
export var colMd4 = "founder-stories-module--colMd4--02973";
export var colMd5 = "founder-stories-module--colMd5--e6b32";
export var colMd6 = "founder-stories-module--colMd6--4a100";
export var colMd7 = "founder-stories-module--colMd7--90e0a";
export var colMd8 = "founder-stories-module--colMd8--30267";
export var colMd9 = "founder-stories-module--colMd9--5b5d8";
export var colSm0 = "founder-stories-module--colSm0--01e03";
export var colSm1 = "founder-stories-module--colSm1--5d259";
export var colSm10 = "founder-stories-module--colSm10--a3baf";
export var colSm11 = "founder-stories-module--colSm11--502e2";
export var colSm12 = "founder-stories-module--colSm12--5c5bc";
export var colSm2 = "founder-stories-module--colSm2--6e4b0";
export var colSm3 = "founder-stories-module--colSm3--4aaab";
export var colSm4 = "founder-stories-module--colSm4--b154c";
export var colSm5 = "founder-stories-module--colSm5--aca85";
export var colSm6 = "founder-stories-module--colSm6--10888";
export var colSm7 = "founder-stories-module--colSm7--0fbb4";
export var colSm8 = "founder-stories-module--colSm8--8c161";
export var colSm9 = "founder-stories-module--colSm9--714ad";
export var container = "founder-stories-module--container--a150c";
export var dot = "founder-stories-module--dot--f6446";
export var dots = "founder-stories-module--dots--a357a";
export var eyebrow = "founder-stories-module--eyebrow--daede";
export var filter = "founder-stories-module--filter--fd802";
export var filterBox = "founder-stories-module--filterBox--c17fb";
export var gridContainer = "founder-stories-module--gridContainer--745fc";
export var head = "founder-stories-module--head--e3462";
export var homepageHeadline = "founder-stories-module--homepageHeadline--f915f";
export var image = "founder-stories-module--image--77743";
export var imageClipped = "founder-stories-module--imageClipped--9ba21";
export var imageClippedEx = "founder-stories-module--imageClippedEx--93c52";
export var imageUnClipped = "founder-stories-module--imageUnClipped--4f94f";
export var inherit = "founder-stories-module--inherit--9359c";
export var internalHeadline = "founder-stories-module--internalHeadline--04167";
export var italic = "founder-stories-module--italic--4fe76";
export var label = "founder-stories-module--label--3625c";
export var leftAlignedButton = "founder-stories-module--leftAlignedButton--5376e";
export var link = "founder-stories-module--link--fd0b4";
export var linkSmall = "founder-stories-module--linkSmall--d7f0e";
export var monobrow = "founder-stories-module--monobrow--74065";
export var moverShaker = "founder-stories-module--moverShaker--c66fb";
export var moverShakerWrapper = "founder-stories-module--moverShakerWrapper--d81b8";
export var quote = "founder-stories-module--quote--b45f2";
export var readMore = "founder-stories-module--readMore--8dcfe";
export var story = "founder-stories-module--story--b9e07";
export var textInput = "founder-stories-module--textInput--dc43a";
export var title = "founder-stories-module--title--d4fba";