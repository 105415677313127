import { hasNames } from "../../utils";
import { graphql } from "gatsby";
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";
import * as React from "react";
import { Fragment, useRef, useState } from "react";



import Button from "../../components/button";
import ContactUsFooter from "../../components/contact-us-footer";
import FoundersStories from "../../components/founder-stories";
import Frame from "../../components/frame";
import { HeadMeta } from "../../components/head-meta";
import InteractiveScroll from "../../components/interactive-scroll";
import InternalHero from "../../components/internal-hero";
import PartnershipBlock from "../../components/partnership-block";



import * as com from "../../styles/common.module.scss";
import * as grd from "../../styles/grid.module.scss";
import * as styles from "../../styles/partnerships--founders.module.scss";


export const Head = () => {
	return <HeadMeta title="Our Partnership with Founders" />;
};
const FoundersPage = ({ data }) => {
	const heroRef = useRef(null);
	const storyRef = useRef(null);
	const partnersRef = useRef(null);
	const contactRef = useRef(null);
	const [activeFilter, setActiveFilter] = useState("");

	const [currentModal, setCurrentModal] = useState(-1);

	const scrollLocations = [
		{ label: "What We Do", ref: heroRef },
		{ label: "Founder Stories", ref: storyRef },
		{ label: "Our Partnerships", ref: partnersRef },
		{ label: "Footer", ref: contactRef },
	];

	return (
		<Frame>
			<InteractiveScroll locations={scrollLocations} />
			<InternalHero
				ref={heroRef}
				eyebrow={"Founders"}
				backdrop={
					<StaticImage
						src={`../../images/heros/founders.jpg`}
						alt="background image"
						aria-hidden="true"
						placeholder="blurred"
						layout="fullWidth"
						loading="eager"
					/>
				}>
				<h1>
					Driven, passionate,
					<br />
					and looking for more
				</h1>
				<p>
					Spanning a variety of industries, our founders and management-owners
					have a few things in common: they’ve all built something special, have
					a successful track record, and are eager to take their business to the
					next level.
				</p>
			</InternalHero>

			<FoundersStories useRef={storyRef} />
			<div className={grd.container}>
			<p className={styles.disclaimerText}>Companies and partnerships described herein do not represent all investment decisions made by Cynosure. The reader should not assume that investment decisions identified and discussed were or will be profitable.  Specific investment references provided herein are for illustrative purposes only and are not necessarily representative of investments that will be made in the future.</p>
			</div>
			<div className={com.monobrow} ref={partnersRef}>
				Portfolio
			</div>
			<h2 className={hasNames(com.centerlineHeader, styles.subHeader)}>
				Our Partnerships
			</h2>
			<div className={com.filterBox}>
				<Button
					type="filter"
					active={activeFilter === ""}
					onClick={() => {
						setActiveFilter("");
					}}>
					Show All
				</Button>
				{data.filters.types.map((type) => {
					return (
						<Button
							key={`filter-type-${type}`}
							type="filter"
							active={activeFilter === type}
							onClick={() => {
								setActiveFilter(type);
							}}>
							{type}
						</Button>
					);
				})}
			</div>

			<div className={styles.subsubHeader}>Growth Equity</div>
			<div className={hasNames(grd.gridContainer, styles.grid)}>
				{data.growthEquity.nodes.map((partner, i) => {
					if (
						activeFilter !== "" &&
						partner.frontmatter.status !== activeFilter
					) {
						return null;
					}
					return (
						<Fragment
							key={`partner block ${partner.frontmatter.first_name} ${i}`}>
							<PartnershipBlock
								className={hasNames(grd.col4, grd.colMd6, grd.colSm12)}
								name={partner.frontmatter.name}
								logo={
									<GatsbyImage
										image={getImage(partner.frontmatter.logo)}
										alt={partner.frontmatter.name}
									/>
								}
								colorLogo={
									<GatsbyImage
										image={getImage(partner.frontmatter.colorLogo)}
										alt={partner.frontmatter.name}
									/>
								}
								investments={partner.frontmatter?.investment_years?.join(", ")}
								status={partner.frontmatter.status}
								description={partner.frontmatter.description}
								cornerClip={
									data.growthEquity.nodes.length - 1 === i ? true : false
								}
								openFn={() => {
									setCurrentModal(i);
									document.body.classList.add("scrollLocked");
								}}
								closeFn={() => {
									setCurrentModal(-1);
									document.body.classList.remove("scrollLocked");
								}}
								nextFn={
									i + 1 === data.growthEquity.nodes.length
										? null
										: () => {
												setCurrentModal(i + 1);
										  }
								}
								prevFn={
									i === 0
										? null
										: () => {
												setCurrentModal(i - 1);
										  }
								}
								isOpen={i === currentModal}
							/>
						</Fragment>
					);
				})}
			</div>

			<div className={styles.subsubHeader}>Credit</div>
			<div className={hasNames(grd.gridContainer, styles.grid)}>
				{data.credit.nodes.map((partner, i) => {
					if (
						activeFilter !== "" &&
						partner.frontmatter.status !== activeFilter
					) {
						return null;
					}
					return (
						<Fragment
							key={`credit block ${partner.frontmatter.first_name} ${
								i + 9000
							}`}>
							<PartnershipBlock
								className={hasNames(grd.col4, grd.colMd6, grd.colSm12)}
								name={partner.frontmatter.name}
								logo={
									<GatsbyImage
										image={getImage(partner.frontmatter.logo)}
										alt={partner.frontmatter.name}
									/>
								}
								colorLogo={
									<GatsbyImage
										image={getImage(partner.frontmatter.colorLogo)}
										alt={partner.frontmatter.name}
									/>
								}
								investments={partner.frontmatter?.investment_years?.join(", ")}
								status={partner.frontmatter.status}
								description={partner.frontmatter.description}
								cornerClip={data.credit.nodes.length - 1 === i ? true : false}
								openFn={() => {
									setCurrentModal(i + 9000);
									document.body.classList.add("scrollLocked");
								}}
								closeFn={() => {
									setCurrentModal(-1);
									document.body.classList.remove("scrollLocked");
								}}
								nextFn={
									i + 1 === data.credit.nodes.length
										? null
										: () => {
												setCurrentModal(i + 9000 + 1);
										  }
								}
								prevFn={
									i === 0
										? null
										: () => {
												setCurrentModal(i + 9000 - 1);
										  }
								}
								isOpen={i + 9000 === currentModal}
							/>
						</Fragment>
					);
				})}
			</div>

			<ContactUsFooter ref={contactRef} />
		</Frame>
	);
};

export default FoundersPage;

export const query = graphql`
	query FoundersPageQuery {
		growthEquity: allMarkdownRemark(
			filter: {
				fileAbsolutePath: { regex: "/src/cms/data/partnerships/" }
				frontmatter: { type: { eq: "Growth Equity" } }
			}
			sort: { fields: frontmatter___name }
		) {
			nodes {
				frontmatter {
					name
					investment_years
					status
					description
					type
					logo {
						childImageSharp {
							gatsbyImageData(
								width: 500
								height: 500
								quality: 90
								backgroundColor: "transparent"
								transformOptions: {
									grayscale: true
									fit: CONTAIN
									cropFocus: CENTER
								}
							)
						}
					}
					colorLogo: logo {
						childImageSharp {
							gatsbyImageData(
								width: 200
								height: 190
								quality: 90
								backgroundColor: "transparent"
								transformOptions: { fit: CONTAIN, cropFocus: CENTER }
							)
						}
					}
				}
			}
		}
		credit: allMarkdownRemark(
			filter: {
				fileAbsolutePath: { regex: "/src/cms/data/partnerships/" }
				frontmatter: { type: { eq: "Credit" } }
			}
			sort: { fields: frontmatter___name }
		) {
			nodes {
				frontmatter {
					name
					investment_years
					status
					description
					type
					logo {
						childImageSharp {
							gatsbyImageData(
								width: 500
								height: 500
								quality: 90
								backgroundColor: "transparent"
								transformOptions: {
									grayscale: true
									fit: CONTAIN
									cropFocus: CENTER
								}
							)
						}
					}
					colorLogo: logo {
						childImageSharp {
							gatsbyImageData(
								width: 200
								height: 190
								quality: 90
								backgroundColor: "transparent"
								transformOptions: { fit: CONTAIN, cropFocus: CENTER }
							)
						}
					}
				}
			}
		}
		filters: allMarkdownRemark(
			filter: { fileAbsolutePath: { regex: "/src/cms/data/partnerships/" } }
			sort: { fields: frontmatter___name, order: DESC }
		) {
			types: distinct(field: frontmatter___status)
		}
	}
`;
